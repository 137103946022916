import type { DateLocales } from '@/types/lang/locales'

export const dateEn: DateLocales = {
    at: 'At {date}',
    date: 'Date',
    dates: 'Dates',
    from: 'From {date}',
    start: 'Start',
    end: 'End',
    to: 'To {date}',

    lastHelloAfterXMinutes: 'Last connection : {value} min. ago',
    lastHelloNow: 'Last connection : now.',
    lastHelloAfter15Minutes: 'Last connection : over 15 min. ago',

    lastHelloDate: 'Last connection : {value}',
    lastHelloAfterXHours: 'Last connection : {value} hour(s) ago.'
}
