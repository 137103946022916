<script setup lang="ts">
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { lastHelloDateParsing } from '@/utils/dateUtils'
import moment from 'moment'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'

type Props = {
    devicePingTime?: Date | null | string
}
const props = defineProps<Props>()

const setPingTime = (value: string | Date | null | undefined) =>
    lastHelloDateParsing(value ? moment(value).toDate() : undefined)
const pingTime = ref(setPingTime(props.devicePingTime))
watch(
    () => props.devicePingTime,
    newPingTime => (pingTime.value = setPingTime(newPingTime)),
    { immediate: true }
)

const interval = ref()
onMounted(
    () =>
        (interval.value = setInterval(() => {
            pingTime.value = setPingTime(props.devicePingTime)
        }, 60000))
)
onBeforeUnmount(() => {
    clearInterval(interval.value)
})
</script>

<template>
    <TypographyItem
        v-if="pingTime"
        :label="
            $t(pingTime?.translation, {
                value: pingTime?.value
            })
        " />
    <TypographyItem v-else :label="$t('date.lastHelloDate', { value: $t('common.unknown') })" />
</template>
