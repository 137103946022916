export const dateFr = {
    at: 'Le {date}',
    date: 'Date',
    dates: 'Dates',
    from: 'Du {date}',
    start: 'Début',
    end: 'Fin',
    to: 'Au {date}',

    lastHelloAfterXMinutes: 'Dernière connexion : il y a {value} min.',
    lastHelloNow: 'Dernière connexion : maintenant.',
    lastHelloAfter15Minutes: 'Dernière connexion : il y a plus de 15 min.',

    lastHelloDate: 'Dernière connexion : {value}',
    lastHelloAfterXHours: 'Dernière connexion : il y a {value} heure(s).'
}
