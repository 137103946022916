import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { DeviceConfigTemplateKey } from '@/types/device/DeviceConfigTemplateKey'
import { EPrimitive } from '@/types/enum/PrimitivesEnum'
import moment from 'moment'
import i18n from '../locales/i18n'
import type { DeviceTemplateKeysModel } from '../types/models/DeviceTemplateKeysModel'
import { booleanToString } from './dataUtils'
import { dateFormats } from './dateUtils'

export const isDateValid = (date: object): boolean => date instanceof Date && !isNaN(date.getTime())

export const isNullOrUndefined = (value: unknown) => value === undefined || value === null

export const stringToType = (data: any) => {
    if (data === 'true' || data === 'false') return 'boolean'
    if (moment(data, dateFormats, true).isValid()) return 'Date'
    if (isNaN(data)) return 'string'
    if (!isNaN(data)) return 'number'
    return 'object'
}

export const dataIsNumberPrimitive = (value: EPrimitive) =>
    value === EPrimitive.NUMBER || value === EPrimitive.FLOAT || value === EPrimitive.INTEGER

export const configDataHasError = (
    templateKey: DeviceConfigTemplateKey,
    value: ExtendedPrimitives
) => {
    if (value && dataIsNumberPrimitive(templateKey.type))
        if (templateKey.range) {
            if (templateKey.range.min && (value as number) < templateKey.range.min) return true
            if (templateKey.range.max && (value as number) > templateKey.range.max) return true
        }
    // eslint-disable-next-line eqeqeq
    if (templateKey.excluded?.filter(item => !!item).find(item => value == item)) return true
    return false
}

export const configDataErrorText = (
    templateKey: DeviceConfigTemplateKey,
    value: ExtendedPrimitives
) => {
    if (!configDataHasError(templateKey, value)) return undefined
    if (value && dataIsNumberPrimitive(templateKey.type) && templateKey.range)
        return i18n.global.t('device.configuration.errors.rangeError', {
            min: templateKey.range.min,
            max: templateKey.range.max
        })
    // eslint-disable-next-line eqeqeq
    if (templateKey.excluded?.filter(item => !!item).find(item => value == item))
        return i18n.global.t('device.configuration.errors.excludedError', {
            values: templateKey.excluded.join(',')
        })
    return undefined
}

export const getKeyValue = (value: ExtendedPrimitives, templateKey?: DeviceTemplateKeysModel) => {
    if (!templateKey) return value?.toString()
    if (!isNullOrUndefined(value)) {
        if (!isNullOrUndefined(templateKey.type)) {
            if (templateKey.type === EPrimitive.BOOLEAN) return booleanToString(!!value)
            if (templateKey.type === EPrimitive.ENUM) {
                const found = templateKey.values?.findIndex(
                    item => value?.toString() === item?.toString()
                )
                return !isNullOrUndefined(found) && found !== -1
                    ? templateKey.values_labels?.[found!]
                    : value?.toString()
            }
            return value?.toString() ?? 'null'
        }
        return value?.toString() ?? 'null'
    }
    return 'null'
}
