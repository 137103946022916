<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { useDebounceFn } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

type Props = {
    filters: DetectionFilters
    deviceOptions?: AssociatedDeviceModel[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
}
const emit = defineEmits<Emits>()

const localDeviceOptions = ref<AssociatedDeviceModel[] | undefined>([])
const name = ref<string>()
const getDevices = (value?: string) =>
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_MY_DEVICES}`, {
            filters: {
                ...props.filters,
                name: value ?? name.value
            },
            sort: getSortByKey(ESortBy.ALPHABETICAL_ASC),
            per_page: EPaginationOptions.DEFAULT
        })
        .then(res => (localDeviceOptions.value = res.data))

watch(
    () => props.deviceOptions,
    newValue => (newValue ? (localDeviceOptions.value = newValue) : getDevices()),
    { immediate: true }
)

const handleChange = (value: EventCheckpointType) => emit('change', value)

const currentDevice = computed(
    () =>
        localDeviceOptions.value?.find(item => item.device_id === props.filters?.device_id)?.name ??
        undefined
)

const handleTyping = (value: string) => {
    if (props.filters.event_id) return
    isLoadingName.value = true
    debouncedName(value)
}

const isLoadingName = ref(false)
const debouncedName = useDebounceFn((value: string) => {
    name.value = value
    getDevices()
    isLoadingName.value = false
}, 500)
</script>

<template>
    <AutocompleteField
        :label="$t('detection.filters.device')"
        :modelValue="currentDevice"
        :options="localDeviceOptions ?? []"
        @typing="handleTyping"
        @select="handleChange({ device_id: ($event as DetectionModel)?.device_id })" />
</template>
